























































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Logs } from '@/api'
import { FormHelper } from '@/mixins'
import { DateManager } from '@/services/Managers'
import { NUMBERS_ONLY_REGEX, SPLIT_COMMAS_REGEX } from '@/services/Validation/regexes'

@Component
export default class CharacterLogs extends Mixins(FormHelper) {
  characterInfo: string = ''
  logType = null
  logTypes: any[] = []
  from = ''
  to = ''
  data: any[] = []
  noResults = false
  displayVnumOnly = false
  forceReload = false
  serverTimeLogs = false

  @Watch('logType')
  onLogTypeChange() {
    this.data = []
  }

  @Watch('displayVnumOnly')
  @Watch('serverTimeLogs')
  async onDisplayVNumOnlysValueChange() {
    if (this.data.length) {
      await this.searchLog()
    }
  }

  async mounted() {
    const logTypes = await Logs.getCharacterLogsTypes()

    this.logTypes = Object.keys(logTypes).map(lt => {
      return {
        value: lt,
        label: logTypes[lt]
      }
    })
  }

  async searchLog() {
    const { characterInfo, logType, from, to, forceReload } = this
    const isCharacterId = NUMBERS_ONLY_REGEX.test(characterInfo)

    const logObject = {
      characterId: isCharacterId ? characterInfo : null,
      characterName: !isCharacterId ? characterInfo : null,
      // @ts-ignore
      logType: logType.value,
      from,
      to,
      forceReload
    }

    try {
      const logs = await Logs.getCharacterLogsBasedOnLogType(logObject)

      if (!logs.length) {
        this.data = []
        this.noResults = true

        return
      }

      const logsKeys = Object.keys(logs[0])
      
      this.data = logs.map(e => {
        const { Location } = e
        const log = {}

        logsKeys.forEach(lk => {
          switch (lk) {
            case 'BoughtItem':
            case 'Box':
            case 'LeftItem':
            case 'Item':
            case 'ItemInstance':
            case 'RightItem':
            case 'Sp':
              log[lk] = this.displayVnumOnly
                ? e[lk].ItemVNum
                : Object.entries(e).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
              break;
            case 'Location':
              log[lk] = `${Location.MapId} (X: ${Location.X} / Y: ${Location.Y})`
              break;
            case 'OriginalUpgrade':
            case 'SumLevel':
              const upgrade = e[lk]
              log[lk] = lk === 'OriginalUpgrade' ? `+${upgrade} (to +${upgrade + 1})` : `Sum ${upgrade + 1}`
              break;
            default:
              log[lk] = e[lk]
              break;
          }

          if (DateManager.isISODateString(e[lk])) {
            log[lk] = this.serverTimeLogs
              ? new Date(e[lk]).toUTCString()
              : new Date(e[lk]).toLocaleString()
          } else if (DateManager.isTimeDurationString(e[lk])) {
            log[lk] = e[lk].split('.')[0]
          }

          const bidKeys = ['startingPrice', 'buyPrice','minBidIncrement', 'bidAmount']

          if (lk.toLowerCase().includes('gold') || bidKeys.includes(lk.toLowerCase())) {
            log[lk] = e[lk].toString().replace(SPLIT_COMMAS_REGEX, ",")
          }
        })

        return log
      })
    } catch(e) {
      console.error('Error fetching Character Logs', e)
    }
  }
}
